import PropTypes from 'prop-types';
import React from 'react';
import { Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      // By using a zIndex higher than the tooltip we make sure the Loader's value is the highest in the application https://material-ui.com/customization/z-index/
      zIndex: theme.zIndex.tooltip + 1,
    },
  }),
  'Loader'
);

// This tiny component is meant to avoid rendering the Backdrop even when not visible
const Loader = ({ visible }) => {
  const classes = useStyles();
  if (!visible) {
    return null;
  }
  return <Backdrop data-testid="backdrop" className={classes.root} open />;
};

Loader.propTypes = {
  visible: PropTypes.bool,
};

Loader.defaultProps = {
  visible: false,
};

export default Loader;
