import types from './types';

const { LOAD_PHONE_COUNTRIES_SUCCESS } = types;

export default () => next => action => {
  if (action.type === LOAD_PHONE_COUNTRIES_SUCCESS) {
    // Set the Countries Phone Extension list in the PhoneInput Options format
    const result = action.result.map(({ code, extension, name }) => ({
      countryCode: code,
      extension,
      name,
    }));
    next({ ...action, result });
    return;
  }

  next(action);
};
