const types = {
  LOAD_PHONE_COUNTRIES: 'app/phone/LOAD_PHONE_COUNTRIES',
  LOAD_PHONE_COUNTRIES_SUCCESS: 'app/phone/LOAD_COUNTRIES_SUCCESS',
  LOAD_PHONE_COUNTRIES_FAIL: 'app/phone/LOAD_PHONE_COUNTRIES_FAIL',
  VALIDATE_PHONE: 'app/phone/VALIDATE_PHONE',
  VALIDATE_PHONE_SUCCESS: 'app/phone/VALIDATE_PHONE_SUCCESS',
  VALIDATE_PHONE_FAIL: 'app/phone/VALIDATE_PHONE_FAIL',
  CLEAR: 'app/phone/CLEAR',
};

export default types;
